import React from 'react'
import { Box } from 'rebass'
import Link from './TextLink'
import { LanguageContext } from './LanguageContext'

const PageNav = (props) => {
  const { translations: t } = React.useContext(LanguageContext)

  return (
    <Box as="nav" pb={4} {...props}>
      <Link
        href="/"
        color="brand.0"
      >
        {t.back}
      </Link>
    </Box>
  )
}

export default PageNav
