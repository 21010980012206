import React from 'react'
import { Flex, Heading, Button, Box } from 'rebass'
import { LanguageContext } from './LanguageContext'
import arrow from '../../static/icons/arrow-right.svg'

const OtherApartmentsCta = () => {
  const { translations: t } = React.useContext(LanguageContext)

  return (
    <Flex
      px={[4, 6]}
      py={[5, 6]}
      bg="bg.dark"
      as="article"
      color="text.light"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        borderRadius: 'cta'
      }}
      textAlign="center"
    >
      <Heading as="h2" fontSize={4} mb={4}>
        {t.otherHouseLink}
      </Heading>
      <Button as="a" href="https://diofa.hu" variant="buttons.brand" px={4} display="flex" flexDirection="row" alignItems="center">
        <span>diofa.hu</span>
        <Box as="span" ml={2}>
          <img src={arrow} alt="diofa.hu" />
        </Box>
      </Button>
    </Flex>
  )

}

export default OtherApartmentsCta
