import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Flex, Box } from 'rebass'
import { Fade } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'

const StyledImg = styled(Img)`
  border-radius: ${(props) => props.theme.radii.card}px;
`

const fadeProperties = {
  duration: 500,
  transitionDuration: 200,
  arrows: false,
  autoplay: false,
  indicators: false,
}

function usePrevious(value) {
  const ref = React.useRef()

  React.useEffect(() => {
    ref.current = value
  })

  return ref.current
}

const Gallery = ({ thumbs = [], images = [] }) => {
  const slideshowEl = React.useRef(null)
  const [index, setIndex] = React.useState(0)
  const prevIndex = usePrevious(index)

  React.useEffect(() => {
    if (prevIndex !== undefined && index !== prevIndex) {
      slideshowEl.current.goTo(index)
    }
  }, [index, prevIndex])

  return (
    <Box>
      <Box maxWidth={425} m={[0, '0 auto']}>
        <Fade {...fadeProperties} ref={slideshowEl}>
          {
            images.map((item, i) => (
              <StyledImg
                key={i}
                fluid={item.childImageSharp.fluid}
              />
            ))
          }
        </Fade>
      </Box>

      <Flex
        mt={1}
        mx={-1}
        sx={{
          display: ['block !important', 'flex'],
          overflowX: ['scroll', 'initial'],
          overflowY: ['hidden', 'initial'],
          whiteSpace: ['nowrap', 'initial'],
          'WebkitOverflowScrolling': 'touch'
        }}
        flexWrap={['initial', 'wrap']}
      >
        {
          thumbs.map((thumb, i) => (
            <Box
              p={1}
              key={i}
              as="button"
              width={[140, 1 / (Math.min(6, thumbs.length))]}
              display="inline-block"
              onClick={() => setIndex(i)}
              sx={{
                border: 0,
                cursor: 'pointer',
                background: 'transparent',
                '&:focus': {
                  outline: 0
                }
              }}
            >
              <StyledImg fluid={thumb.childImageSharp.fluid} />
            </Box>
          ))
        }
      </Flex>
    </Box>
  )
}

export default Gallery
