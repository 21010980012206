import React from 'react'
import { Box, Heading, Text } from 'rebass'
import List from './List'
import ListItem from './ListItem'
import Divider from './Divider'
import { ExternalLink } from './TextLink'

const ContactCard = ({ translations: t = {}, contactInfo = {} }) => {
  return (
    <Box
      p={3}
      as="aside"
      bg="brand.1"
      color="white"
    >
      <Heading as="h3">
        {t.section.infobar.title}
        <Divider borderColor="white" mt={2} />
      </Heading>

      <List pt={3}>
        <ListItem py={2}>
          <Heading as="h4" fontSize={2}>{t.section.contact.email}</Heading>
          <ExternalLink color="inherit" href={`mailto:${contactInfo.emailAddress}`}>{contactInfo.emailAddress}</ExternalLink>
        </ListItem>
        <ListItem py={2}>
          <Heading as="h4" fontSize={2}>{t.section.contact.phone}</Heading>
          <ExternalLink href={`tel:${contactInfo.phoneNumber}`}>{contactInfo.phoneNumber}</ExternalLink>
        </ListItem>
        <ListItem py={2}>
          <Heading as="h4" fontSize={2}>{t.section.contact.address}</Heading>
          <Text>{contactInfo.address}</Text>
        </ListItem>
      </List>
    </Box>
  )
}

export default ContactCard
