import React from 'react'
import { graphql } from 'gatsby'
import galite from 'ga-lite'
import orderBy from 'lodash/orderBy'
import { Flex, Box, Heading, Text, Button } from 'rebass'
import styled from '@emotion/styled'
import htmlParser from 'html-react-parser'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageHeader from '../components/PageHeader'
import Gallery from '../components/Gallery'
import Divider from '../components/Divider'
import ContactCard from '../components/ContactCard'
import ApartmentList from '../components/ApartmentList'
import SectionHeading from '../components/SectionHeading'
import PageNav from '../components/PageNav'
import OtherApartmentsCta from '../components/OtherApartmentsCta'

const StyledContent = styled(Box)`
  min-height: 600px;
`

const DividedHeading = ({ children, ...props }) => (
  <Box mb={3} {...props}>
    <Heading mb={1} as="h2" children={children} />
    <Divider />
  </Box>
)

const ContentBlock = ({ title, description, ...props }) => (
  <Box mt={4} {...props}>
    <DividedHeading>
      {htmlParser(title)}
    </DividedHeading>
    <Text color="text.default">
      {htmlParser(description)}
    </Text>
  </Box>
)

const ApartmentTemplate = ({ data, pageContext, path }) => {
  const translations = data.translations.childTranslationsJson
  const content = data.content.childContentJson

  const apartment = content.apartments.find((apartment) => apartment.id === pageContext.apartmentId)
  const otherApartments = orderBy(content.apartments, ['area'], ['desc'])
    .filter((ap) => ap.id !== apartment.id)

  const pricingTranslations = translations.apartmentDetails.section.pricing

  const trackBookingStart = React.useCallback(() => {
    galite('send', 'event', 'Book', 'click', apartment.id)
  }, [apartment.id])

  const thumbs = data.wp.apartment.daMediaGallery.map(({ thumb }) => thumb)
  const images = data.wp.apartment.daMediaGallery.map(({ image }) => image)

  return (
    <Layout
      seo={{
        lang: pageContext.language,
        title: pageContext.title,
        description: translations.meta.description,
        shareImage: data.shareImage.childImageSharp.fixed
      }}
      translations={translations}
      apartments={content.apartments}
    >
      <Box>
        <PageHeader
          path={path}
          variant="small"
          paths={pageContext.paths}
          heroTitle={pageContext.title}
          title={translations.meta.title}
        />
      </Box>
      <Container>
        <StyledContent py={4} pb={5}>
          <PageNav />
          <Flex mx={-2} flexDirection="row" flexWrap="wrap">
            <Box as="section" px={2} pb={4} width={[1, 1, 2 / 3]}>
              <Gallery
                thumbs={thumbs}
                images={images}
              />

              <Box my={4} textAlign="center">
                <Button as="a" href={apartment.bookingUrl} p={3} px={4} fontSize={3} variant="buttons.brand" onClick={trackBookingStart}>
                  {translations.apartmentDetails.bookAction}
                </Button>
              </Box>

              <Box mt={4}>
                <Text color="text.default">
                  {htmlParser(apartment.description)}
                </Text>
              </Box>

              <Box mt={4}>
                <DividedHeading>
                  {htmlParser(translations.apartmentDetails.section.pricing.title)}
                </DividedHeading>
                <Box color="text.default">
                  <Text>
                    {htmlParser(pricingTranslations.holidayConditions)}
                  </Text>
                  <Text mt={3}>
                    <strong>{pricingTranslations.touristTax.title}</strong>: {pricingTranslations.touristTax.description}
                  </Text>
                  <Text mb={3}>
                    <strong>{pricingTranslations.pets.title}</strong>: {pricingTranslations.pets.description}
                  </Text>
                  <Text>
                    {pricingTranslations.smallPrint}
                  </Text>
                </Box>
              </Box>

              <ContentBlock
                title={translations.apartmentDetails.section.booking.title}
                description={translations.apartmentDetails.section.booking.description}
              />

              <Box my={4} textAlign="center">
                <Button as="a" href={apartment.bookingUrl} p={3} px={4} fontSize={3} variant="buttons.brand" onClick={trackBookingStart}>
                  {translations.apartmentDetails.bookAction}
                </Button>
              </Box>

              <ContentBlock
                title={translations.apartmentDetails.section.termsOfPayment.title}
                description={translations.apartmentDetails.section.termsOfPayment.description}
              />

              <ContentBlock
                title={translations.apartmentDetails.section.termsOfCancellation.title}
                description={translations.apartmentDetails.section.termsOfCancellation.description}
              />
            </Box>
            <Box px={2} width={[1, 1, 1 / 3]}>
              <ContactCard
                contactInfo={content}
                translations={translations}
              />
            </Box>
          </Flex>

          <Box as="section" mt={4}>
            <SectionHeading>
              {translations.apartmentDetails.section.otherApartments.title}
            </SectionHeading>

            <ApartmentList
              apartments={otherApartments}
              images={data.apartmentPreviews.edges}
            />
          </Box>
          <Box as="section" mt={4}>
            <OtherApartmentsCta />
          </Box>
        </StyledContent>
      </Container>
    </Layout>
  )
}

export default ApartmentTemplate

export const pageQuery = graphql`
  query ApartmentQuery($language: String!, $apartmentId: ID!) {
    wp {
      apartment(id: $apartmentId, idType: SLUG) {
        daMediaGallery {
          src: sourceUrl(size: LARGE)
          sourceUrl(size: LARGE)
          mediaItemId: databaseId
          modified
          thumb: imageFile {
            childImageSharp {
              fluid(maxWidth: 140, maxHeight: 100, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image: imageFile {
            childImageSharp {
              fluid(maxWidth: 650, maxHeight: 500, quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    content: file(
      name: { eq: $language },
      absolutePath: {regex: "/content/"}
    ) {
      childContentJson {
        address
        phoneNumber
        emailAddress
        apartments {
          name
          id
          path
          maxPeople
          roomCount
          description
          bookingUrl
          pageTitle
        }
      }
    }

    apartmentPreviews: allFile(
      filter: {
        relativePath: { regex: "/images/apartmentPreview/" }
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
      }
    ) {
      totalCount
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 325, quality: 40) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }

    shareImage: file(
      name: { eq: "share" }
    ) {
      childImageSharp {
        fixed(width: 1200) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    translations: file(
      name: { eq: $language }
      absolutePath: {regex: "/translations/"}
    ) {
      childTranslationsJson {
        ...CommonTranslationFields
        apartmentDetails {
          bookAction
          section {
            pricing {
              title
              holidayConditions
              smallPrint
              touristTax {
                title
                description
              }
              pets {
                title
                description
              }
            }
            booking {
              title
              description
            }
            termsOfPayment {
              title
              description
            }
            termsOfCancellation {
              title
              description
            }
            otherApartments {
              title
            }
          }
        }
      }
    }
  }
`
